import Highcharts from 'highcharts';
import { switchDateFormat } from '@/helpers/format-date-service';

export const getIntervalStep = (count = 0, interval = null) => {
    const fixedRanges = {
        20: 4,
        23: 4,
        26: 5,
        29: 4,
        30: 5,
        31: 5,
        32: 4
    }
    if(fixedRanges[count]){
        return fixedRanges[count];
    } else if (count > 20 && count < 32){
        return 3;
    }
    const num = interval ? interval : count % 2 === 0 ? 8 : 9;

    return interval ? Math.round(count/num) : count > 12 ? 2 : 1;
}

export const config = ({data, lineColor, areaColor, name }) => {
    const step = getIntervalStep(data?.length);
    const categories = data.map(item=>item[0]) || [];

    return {
        chart: {
            zoomType: 'x',
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        title:{
            text:''
        },
        xAxis: {
            min: 0,
            max: categories.length - 1 || 1,
            endOnTick: true,
            showLastLabel: true,
            startOnTick: true,
            tickLength: 0,
            tickInterval: 1,
            labels: {
                step: 1,
                formatter: function() {
                    if(this.isLast) return 'latest .'
                    if(this.pos % step !== 0){

                        return ''
                    }

                    return categories[this.value]!=='latest' ? Highcharts.dateFormat(data?.length <= 32 ? '%e %b' : '%b', new Date(categories[this.value])): categories[this.value];
                },
                style: {
                    ...(categories?.length >= 20 ? {whiteSpace: 'nowrap'} : {} )
                }
            },
        },
        yAxis: {
            title: undefined,
            min: 0,
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        tooltip: {
            useHTML: true,
            formatter: function() {
                return (`
                    <div>
                        <div style="font-size: 10px;">
                            ${categories[this.x] === 'latest' ? `latest` : `${switchDateFormat(new Date(categories[this.x]), 'l F jS, Y')}`}
                        </div>
                        <div style="margin-top: 2px; display: flex; align-items: center;">
                          <div style="margin-right: 3px; border-radius: 50%; width: 5px; height: 5px; background: ${this.color}"></div>
                          ${name}: <b>${this.y}</b>
                        </div>
                    </div>
                `)
            },
        },
        plotOptions: {
            area: {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.color(areaColor).setOpacity(0.3).get('rgba')]
                    ]
                },
                marker: {
                    radius: 2
                },
                lineWidth: 2,
                threshold: null
            },
            series: {
                marker: {
                    enabled: false
                }
            }
        },
        series: [{
            type: 'area',
            color: lineColor,
            name,
            data: data.map(item=>item[1])
        }]
    }
};

const mapSeriesData = data => {
    return data?.length ? data.map((value) => {
        return {
            color: value.color,
            data: value.data,
            marker: {symbol: 'circle'},
            name: value.name,
            type: 'line'
        };
    }) : [
        {data: []},
        {data: []}
    ];
};

export const multiLineChartConfig = ({ data, chartStyles, categories }) => {
    const step = getIntervalStep(categories?.length, 6);

    return {
        chart: {
            zoomType: 'x',
            ...(chartStyles ? chartStyles : {})
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        title:{
            text:''
        },
        xAxis: {
            min: 0,
            max: categories?.length - 1 || 1,
            endOnTick: true,
            showLastLabel: true,
            startOnTick: true,
            tickLength: 0,
            tickInterval: 1,
            labels: {
                align: 'left',
                step: 1,
                formatter: function() {
                    if(this.isLast) return 'latest';

                    if(this.pos % step !== 0){
                        return '';
                    }

                    return categories ? switchDateFormat(new Date(categories[this.value]), 'chart-date') : '';
                },
                style: {
                    ...(categories?.length >= 20 ? {whiteSpace: 'nowrap'} : {} )
                }
            },
        },
        yAxis: {
            title: undefined,
            min: 0,
            opposite: true
        },
        legend: {
            align: 'left',
            itemStyle: {
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: 400,
                color: '#909399'
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false
                }
            }
        },
        tooltip: {
            shared: true,
            backgroundColor: '#ffffff',
            borderColor: '#AEBBC8',
            useHTML: true,
            formatter: function() {
                return (`
                    <div>
                        <div style="font-size: 10px;">
                            ${categories[this.x] === 'latest' ? `latest` : `${switchDateFormat(new Date(categories[this.x]), 'new-note')}`}
                        </div>
                        <div style="margin-top: 2px; display: flex; align-items: center;">
                            <div style="margin-right: 3px; border-radius: 50%; width: 5px; height: 5px; background: ${this.points[0].color}"></div>
                            ${this.points[0].series.name}: <b style="padding-left: 4px;">${this.points[0].y}</b>
                        </div>
                        <div style="margin-top: 2px; display: flex; align-items: center;">
                            <div style="margin-right: 3px; border-radius: 50%; width: 5px; height: 5px; background: ${this.points[1].color}"></div>
                            ${this.points[1].series.name}: <b style="padding-left: 4px;">${this.points[1].y}</b>
                      </div>
                    </div>
                `)
            },
        },
        series: mapSeriesData(data)
    };
}
